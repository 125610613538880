import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

const About = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
    threshold: 0.1, // Percentage of the element that is in view before the callback triggers
  });

  const data = useStaticQuery(graphql`
    {
      workstation: file(relativePath: { eq: "ceo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 384
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const metrics = [
    { count: "4+", label: "YEARS OF EXPERIENCE" },
    { count: "13+", label: "APPS SHIPPED" },
    { count: "7+", label: "Years of Computer Science Education" },
  ];
  return (
    <div id="#about">
      <div className={`container mx-auto`}>
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12 items-center lg:py-28 md:pt-12 md:pb-28 pt-10 pb-20">
          <div className="lg:col-span-5 flex flex-row items-center gap-8">
            <div className="flex flex-col xl:pr-8">
              <h2 className="md:text-display-lg text-display-md font-semibold md:pb-6 pb-4">
                A bit about our founder
              </h2>
              <p
                className={`text-body-md font-normal text-neutral-700 ${
                  inView ? "animate-slideInUp" : "opacity-0"
                }`}
                ref={ref}
              >
                I'm El Walid Kadura, the founder and chief developer at Kadura
                Web Services.
                <br></br>
                <br></br>
                With an engineer degree and with several years spent navigating
                the complex world of web development in an advertising agency
                setting, I've garnered an extensive understanding of what it
                takes to make a website not just functional, but also
                captivating and optimized for search engines
                <br></br>
                <br></br>
                My dedication to client's success and passion for web
                development drive me to create user-centric websites that truly
                resonate with audiences and excel in today's digital landscape
              </p>
            </div>
          </div>
          <div className="lg:col-span-7 lg:grid lg:grid-cols-7 flex md:flex-row flex-col items-center md:gap-8 gap-12">
            <div className="lg:col-span-4 flex">
              <GatsbyImage
                image={getImage(data.workstation)}
                alt="Workstation"
              />
            </div>
            <div
              className={`lg:col-span-3 flex md:self-auto self-stretch grow flex-col xl:gap-16 lg:gap-8 gap-12 xl:pl-8 ${
                inView ? "animate-slideInRight" : "opacity-0"
              }`}
            >
              {metrics.map((item) => (
                <div
                  key={item.label}
                  className="flex flex-col self-stretch gap-2 pb-4 border-b border-solid border-neutral-400"
                >
                  <p className="md:text-display-lg text-display-md font-semibold">
                    {item.count}
                  </p>
                  <p className="text-body-sm font-semibold tracking-2 text-neutral-500">
                    {item.label}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
